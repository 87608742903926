import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { cartApi } from '../services/api';
import { differenceInHours } from 'date-fns';

// Utility function for rental period calculations
const calculateTotalHours = (period, value) => {
  switch (period) {
    case 'hourly':
      return value;
    case 'daily':
      return value * 24;
    case 'weekly':
      return value * 24 * 7;
    case 'monthly':
      return value * 24 * 30;
    default:
      return value;
  }
};

const CartContext = createContext(null);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(null);

  // Validation helper for cart data
  const validateCartData = useCallback((data) => {
    if (!data) {
   
      return null;
    }

    try {
      let items = [];
      let total_items = 0;
      let total_price = 0;
      let discount = 0;

      if (data.items && Array.isArray(data.items)) {
        items = data.items;
        total_items = data.total_items || items.length;
        total_price = data.total_price || 0;
        discount = data.coupon?.discount_value || 0;
      } else if (Array.isArray(data)) {
        items = data;
        total_items = data.length;
        total_price = data.reduce((sum, item) => sum + (item.subtotal || 0), 0);
      } else if (data.data) {
        return validateCartData(data.data);
      } else {
        
        return null;
      }

      items = items.filter(item => (
        item &&
        item.asset &&
        item.asset.id &&
        item.quantity > 0 &&
        item.hours > 0
      )).map(item => ({
        id: item.id,
        asset: {
          id: item.asset.id,
          name: item.asset.name || 'Unknown Asset',
          slug: item.asset.slug || '',
          hourly_rate: parseFloat(item.asset.hourly_rate) || 0,
          daily_rate: parseFloat(item.asset.daily_rate) || 0,
          weekly_rate: parseFloat(item.asset.weekly_rate) || 0,
          monthly_rate: parseFloat(item.asset.monthly_rate) || 0,
          description: item.asset.description || '',
          category: item.asset.category || null,
          images: item.asset.images || [],
          minimum_rental_period: item.asset.minimum_rental_period || 4,
          min_rental_hours: item.asset.min_rental_hours,
          max_rental_hours: item.asset.max_rental_hours,
          min_rental_days: item.asset.min_rental_days,
          max_rental_days: item.asset.max_rental_days,
          min_rental_weeks: item.asset.min_rental_weeks,
          max_rental_weeks: item.asset.max_rental_weeks,
          min_rental_months: item.asset.min_rental_months,
          max_rental_months: item.asset.max_rental_months
        },
        quantity: parseInt(item.quantity) || 1,
        hours: parseInt(item.hours) || 1,
        rental_period: item.rental_period || 'hourly',
        price: parseFloat(item.price) || 0,
        subtotal: parseFloat(item.subtotal) || 0,
        hourly_rate: parseFloat(item.hourly_rate) || 0
      }));

      return {
        items,
        total_items,
        total_price,
        coupon_discount: discount
      };
    } catch (err) {
   
      return null;
    }
  }, []);

  // Enhanced rental period validation
  const validateRentalPeriod = useCallback((hours, minimumPeriod, asset, period = 'hourly') => {
    // Keep original validation for backward compatibility
    if (!asset) {
      const minimumHours = minimumPeriod || 4;
      if (hours < minimumHours) {
        throw new Error(`Minimum rental period is ${minimumHours} hours`);
      }
      return true;
    }

    const totalHours = calculateTotalHours(period, hours);
    const minimumHours = minimumPeriod || asset.minimum_rental_period || 4;

    // Period-specific validation
    switch (period) {
      case 'hourly':
        if (totalHours < minimumHours) {
          throw new Error(`Minimum rental period is ${minimumHours} hours`);
        }
        if (asset.max_rental_hours && totalHours > asset.max_rental_hours) {
          throw new Error(`Maximum rental period is ${asset.max_rental_hours} hours`);
        }
        break;

      case 'daily': {
        const minDays = asset.min_rental_days || Math.ceil(minimumHours/24);
        if (hours < minDays) {
          throw new Error(`Minimum rental period is ${minDays} days`);
        }
        if (asset.max_rental_days && hours > asset.max_rental_days) {
          throw new Error(`Maximum rental period is ${asset.max_rental_days} days`);
        }
        break;
      }

      case 'weekly': {
        const minWeeks = asset.min_rental_weeks || Math.ceil(minimumHours/(24*7));
        if (hours < minWeeks) {
          throw new Error(`Minimum rental period is ${minWeeks} weeks`);
        }
        if (asset.max_rental_weeks && hours > asset.max_rental_weeks) {
          throw new Error(`Maximum rental period is ${asset.max_rental_weeks} weeks`);
        }
        break;
      }

      case 'monthly': {
        const minMonths = asset.min_rental_months || Math.ceil(minimumHours/(24*30));
        if (hours < minMonths) {
          throw new Error(`Minimum rental period is ${minMonths} months`);
        }
        if (asset.max_rental_months && hours > asset.max_rental_months) {
          throw new Error(`Maximum rental period is ${asset.max_rental_months} months`);
        }
        break;
      }

      default:
        if (totalHours < minimumHours) {
          throw new Error(`Minimum rental period is ${minimumHours} hours`);
        }
    }

    return true;
  }, []);

  // Fetch cart data
  const fetchCart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.getCart();
      const validatedData = validateCartData(response);

      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
      } else {
        setCartItems([]);
        setTotalItems(0);
        setTotalPrice(0);
        setCouponDiscount(0);
      }
      
      return validatedData;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to fetch cart';

      setError(errorMessage);
      setCartItems([]);
      setTotalItems(0);
      setTotalPrice(0);
      setCouponDiscount(0);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [validateCartData]);

  // Enhanced addToCart with rental period support
  const addToCart = useCallback(async (assetId, quantity = 1, hours = 1, minimumPeriod, asset = null, period = 'hourly') => {
    if (!assetId) {

      return;
    }

    try {
      try {
        validateRentalPeriod(hours, minimumPeriod, asset, period);
      } catch (validationError) {
   
        throw validationError;
      }

      setLoading(true);
      setError(null);

      const totalHours = calculateTotalHours(period, hours);

      const response = await cartApi.addToCart({
        asset_id: parseInt(assetId),
        quantity: parseInt(quantity),
        hours: parseInt(totalHours),
        rental_period: period
      });

      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to add item to cart';
   
      setError(errorMessage);
      if (!err.message?.includes('Minimum rental period')) {
  
      }
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData, validateRentalPeriod]);

  // Enhanced updateCartItem with rental period support
  const updateCartItem = useCallback(async (assetId, updates, period) => {
    if (!assetId) {

      return;
    }

    try {
      if (updates.hours !== undefined) {
        const item = cartItems.find(item => item.asset.id === parseInt(assetId));
        if (item) {
          try {
            validateRentalPeriod(updates.hours, null, item.asset, period || 'hourly');
          } catch (validationError) {
   
            throw validationError;
          }
        }
      }

      setLoading(true);
      setError(null);

      const requestData = {
        asset_id: parseInt(assetId)
      };

      if (updates.quantity !== undefined) {
        requestData.quantity = parseInt(updates.quantity);
      }

      if (updates.hours !== undefined) {
        requestData.hours = parseInt(calculateTotalHours(period || 'hourly', updates.hours));
      }

      if (period) {
        requestData.rental_period = period;
      }

      const response = await cartApi.updateCartItem(requestData);
      
      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to update cart';
    
      setError(errorMessage);
      if (!err.message?.includes('Minimum rental period')) {
    
      }
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData, validateRentalPeriod, cartItems]);

  // Remove item from cart
  const removeFromCart = useCallback(async (assetId) => {
    if (!assetId) {
     
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.removeFromCart(parseInt(assetId));
      
      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to remove item from cart';
    
      setError(errorMessage);

      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData]);

  // Clear cart
  const clearCart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.clearCart();
      
      setCartItems([]);
      setTotalItems(0);
      setTotalPrice(0);
      setCouponDiscount(0);
      setLastUpdate(new Date());
   

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to clear cart';
     
      setError(errorMessage);
 
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  // Apply coupon
  const applyCoupon = useCallback(async (code) => {
    if (!code) {

      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.applyCoupon(code);
      
      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
    
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to apply coupon';
     
      setError(errorMessage);

      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData]);

  // Calculate subtotal
  const calculateSubtotal = useCallback(() => {
    return cartItems.reduce((total, item) => {
      return total + (item.subtotal || 0);
    }, 0);
  }, [cartItems]);

  // Get discounted total
  const getDiscountedTotal = useCallback(() => {
    const subtotal = calculateSubtotal();
    return Math.max(0, subtotal - couponDiscount);
  }, [calculateSubtotal, couponDiscount]);

  // Check if item exists in cart
  const isInCart = useCallback((assetId) => {
    return cartItems.some(item => item.asset.id === parseInt(assetId));
  }, [cartItems]);

  // Get cart item quantity
  const getCartItemQuantity = useCallback((assetId) => {
    const item = cartItems.find(item => item.asset.id === parseInt(assetId));
    return item ? item.quantity : 0;
  }, [cartItems]);

  // Get cart item rental period
  const getCartItemRentalPeriod = useCallback((assetId) => {
    const item = cartItems.find(item => item.asset.id === parseInt(assetId));
    return item ? item.rental_period : 'hourly';
  }, [cartItems]);

  // Get cart item hours
  const getCartItemHours = useCallback((assetId) => {
    const item = cartItems.find(item => item.asset.id === parseInt(assetId));
    if (!item) return 0;

    // Convert total hours back to the selected rental period unit
    const totalHours = item.hours;
    switch (item.rental_period) {
      case 'hourly':
        return totalHours;
      case 'daily':
        return Math.ceil(totalHours / 24);
      case 'weekly':
        return Math.ceil(totalHours / (24 * 7));
      case 'monthly':
        return Math.ceil(totalHours / (24 * 30));
      default:
        return totalHours;
    }
  }, [cartItems]);

  // Initialize cart on mount
  useEffect(() => {
    fetchCart().catch(err => {

    });
  }, [fetchCart]);

  const value = {
    cartItems,
    totalItems,
    totalPrice,
    loading,
    error,
    couponDiscount,
    lastUpdate,
    addToCart,
    removeFromCart,
    updateCartItem,
    clearCart,
    fetchCart,
    applyCoupon,
    calculateSubtotal,
    getDiscountedTotal,
    isInCart,
    getCartItemQuantity,
    getCartItemRentalPeriod,
    getCartItemHours,
    validateRentalPeriod
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;