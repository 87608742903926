import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { WishlistProvider } from './contexts/WishlistContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleMapsProvider } from './contexts/GoogleMapsContext';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import VendorRoute from './components/VendorRoute';
import ReferralRedirect from './components/ReferralRedirect';
import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';

// Lazy loaded components
const LoginPage = lazy(() => import('./pages/LoginPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const AccountActivationPage = lazy(() => import('./pages/AccountActivationPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const AssetsPage = lazy(() => import('./pages/AssetsPage'));
const AssetDetailsPage = lazy(() => import('./pages/AssetDetailsPage'));
const SearchResultsPage = lazy(() => import('./pages/SearchResultsPage'));
const PopularAssetsPage = lazy(() => import('./pages/PopularAssetsPage'));
const ExploreAssetsPage = lazy(() => import('./pages/ExploreAssetsPage'));
const CategoriesPage = lazy(() => import('./pages/CategoriesPage'));
const NewArrivalsPage = lazy(() => import('./pages/NewArrivalsPage'));
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
const UserPreferencesPage = lazy(() => import('./pages/UserPreferencesPage'));
const WishlistPage = lazy(() => import('./pages/WishlistPage'));
const CartPage = lazy(() => import('./pages/CartPage'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const OrderConfirmationPage = lazy(() => import('./pages/OrderConfirmationPage'));
const VendorDashboard = lazy(() => import('./pages/VendorDashboard'));
const BecomeVendorPage = lazy(() => import('./pages/BecomeVendorPage'));
const ListAssetPage = lazy(() => import('./pages/ListAssetPage'));
const AdminDashboard = lazy(() => import('./pages/AdminDashboard'));
const AnalyticsPage = lazy(() => import('./pages/AnalyticsPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const NotificationLogPage = lazy(() => import('./pages/NotificationLogPage'));
const NotificationAnalyticsPage = lazy(() => import('./pages/NotificationAnalyticsPage'));
const PayPalPaymentPage = lazy(() => import('./pages/payment/PayPalPaymentPage'));
const MpesaPaymentPage = lazy(() => import('./pages/payment/MpesaPaymentPage'));
const FlutterwavePaymentPage = lazy(() => import('./pages/payment/FlutterwavePaymentPage'));
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const FAQPage = lazy(() => import('./pages/FAQPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('./pages/TermsOfServicePage'));
const CancellationPolicyPage = lazy(() => import('./pages/CancellationPolicyPage'));
const AboutUsPage = lazy(() => import('./pages/AboutUsPage'));
const HowItWorksPage = lazy(() => import('./pages/HowItWorksPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));

// Suspense loader component
const SuspenseLoader = () => (
  <div className="flex items-center justify-center h-screen">
    <LoadingSpinner />
  </div>
);

// AuthProvider with Router wrapper
function AuthProviderWithRouter({ children }) {
  const navigate = useNavigate();
  return <AuthProvider navigate={navigate}>{children}</AuthProvider>;
}

// Main content component with routes
function AppContent() {
  const { loading } = useAuth();

  if (loading) {
    return <SuspenseLoader />;
  }

  return (
    <>
      <ScrollToTop />
      <Layout>
        
          <Suspense fallback={<SuspenseLoader />}>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/assets" element={<AssetsPage />} />
              <Route path="/assets/:slug" element={<AssetDetailsPage />} />
              <Route path="/search-results" element={<SearchResultsPage />} />
              <Route path="/popular" element={<PopularAssetsPage />} />
              <Route path="/explore" element={<ExploreAssetsPage />} />
              <Route path="/categories" element={<CategoriesPage />} />
              <Route path="/new" element={<NewArrivalsPage />} />

              {/* Auth Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password/:uidb64/:token" element={<ResetPasswordPage />} />
              <Route path="/activate/:uidb64/:token" element={<AccountActivationPage />} />
              <Route path="/ref/:code" element={<ReferralRedirect />} />

              {/* Protected User Routes */}
              <Route path="/profile" element={<ProtectedRoute><UserProfilePage /></ProtectedRoute>} />
              <Route path="/profile/orders" element={<ProtectedRoute><OrderConfirmationPage /></ProtectedRoute>} />
              <Route path="/profile/preferences" element={<ProtectedRoute><UserPreferencesPage /></ProtectedRoute>} />
              <Route path="/wishlist" element={<ProtectedRoute><WishlistPage /></ProtectedRoute>} />
              <Route path="/cart" element={<ProtectedRoute><CartPage /></ProtectedRoute>} />
              <Route path="/checkout" element={<ProtectedRoute><CheckoutPage /></ProtectedRoute>} />
              <Route path="/order-confirmation/:orderId" element={<ProtectedRoute><OrderConfirmationPage /></ProtectedRoute>} />
              <Route path="/order-confirmation" element={<ProtectedRoute><OrderConfirmationPage /></ProtectedRoute>} />

              {/* Vendor Routes */}
              <Route
                path="/vendor/dashboard"
                element={
                  <ProtectedRoute>
                    <VendorRoute>
                      <VendorDashboard />
                    </VendorRoute>
                  </ProtectedRoute>
                }
              />
              <Route path="/become-vendor" element={<ProtectedRoute><BecomeVendorPage /></ProtectedRoute>} />
              <Route
                path="/list-asset"
                element={
                  <ProtectedRoute>
                    <VendorRoute>
                      <ListAssetPage />
                    </VendorRoute>
                  </ProtectedRoute>
                }
              />

              {/* Admin Routes */}
              <Route path="/admin/dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
              <Route path="/admin/analytics" element={<AdminRoute><AnalyticsPage /></AdminRoute>} />
              <Route path="/admin/reports" element={<AdminRoute><ReportsPage /></AdminRoute>} />
              <Route path="/admin/notifications" element={<AdminRoute><NotificationLogPage /></AdminRoute>} />
              <Route path="/admin/notification-analytics" element={<AdminRoute><NotificationAnalyticsPage /></AdminRoute>} />

              {/* Payment Routes */}
              <Route path="/payment/paypal" element={<ProtectedRoute><PayPalPaymentPage /></ProtectedRoute>} />
              <Route path="/payment/mpesa" element={<ProtectedRoute><MpesaPaymentPage /></ProtectedRoute>} />
              <Route path="/payment/flutterwave" element={<ProtectedRoute><FlutterwavePaymentPage /></ProtectedRoute>} />

              {/* Static Pages */}
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/privacy" element={<PrivacyPolicyPage />} />
              <Route path="/terms" element={<TermsOfServicePage />} />
              <Route path="/cancellation" element={<CancellationPolicyPage />} />
              <Route path="/about" element={<AboutUsPage />} />
              <Route path="/how-it-works" element={<HowItWorksPage />} />

              {/* 404 Route */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
      
      </Layout>

      <div
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

// Root App component with all providers
function App() {
  return (
 
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <AuthProviderWithRouter>
            <CartProvider>
              <WishlistProvider>
                <GoogleMapsProvider>
                  <AppContent />
                </GoogleMapsProvider>
              </WishlistProvider>
            </CartProvider>
          </AuthProviderWithRouter>
        </Router>
      </GoogleOAuthProvider>
   
  );
}

export default App;
