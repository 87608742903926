import React, { createContext, useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import authService, { USER_ROLES, AUTH_EVENTS } from '../services/AuthService';
import { debounce } from 'lodash';

const DEBUG = process.env.NODE_ENV === 'development';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AUTH_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  INITIALIZED: 'initialized'
};

export const AuthProvider = ({ children, navigate }) => {
  const [authState, setAuthState] = useState(AUTH_STATES.LOADING);
  const [user, setUser] = useState(() => {
    try {
      const token = authService.getAccessToken();
      if (!token || !authService.validateToken(token)) {
        return null;
      }
      const initialUser = authService.getUser();
      return initialUser ? authService.normalizeUserData(initialUser) : null;
    } catch (error) {
      return null;
    }
  });

  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const isMounted = useRef(true);
  const initAttempts = useRef(0);
  const isCleaningUp = useRef(false);
  const lastUserCheck = useRef(Date.now());
  
  const USER_CHECK_INTERVAL = 30 * 60 * 1000;
  const MAX_INIT_ATTEMPTS = 3;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      authService.cleanup();
    };
  }, []);

  const handleError = useCallback((error, defaultMessage) => {
    const errorMessage = error.response?.data?.message || 
                        error.response?.data?.detail ||
                        error.message || 
                        defaultMessage || 
                        'An error occurred';
    
    if (isMounted.current) {
      setError(errorMessage);

    }
    return errorMessage;
  }, []);

  const initializeAuth = useCallback(async () => {
    if (!isMounted.current || initAttempts.current >= MAX_INIT_ATTEMPTS) {
      setAuthState(AUTH_STATES.IDLE);
      setIsInitialized(true);
      return;
    }

    try {
      initAttempts.current++;
      const token = authService.getAccessToken();
      
      if (!token || !authService.validateToken(token)) {
        setAuthState(AUTH_STATES.IDLE);
        setIsInitialized(true);
        setUser(null);
        return;
      }

      const userData = await authService.initializeFromStorage();
      
      if (!isMounted.current) return;

      if (userData) {
        setUser(authService.normalizeUserData(userData));
        setAuthState(AUTH_STATES.SUCCESS);
      } else {
        setUser(null);
        setAuthState(AUTH_STATES.IDLE);
      }
    } catch (error) {
      if (isMounted.current) {
        setUser(null);
        setAuthState(AUTH_STATES.IDLE);
      }
    } finally {
      if (isMounted.current) {
        setIsInitialized(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!isInitialized && !isCleaningUp.current) {
      initializeAuth();
    }
  }, [isInitialized, initializeAuth]);

  const checkAuthStatus = useCallback(async (force = false) => {
    if (!isInitialized || !isMounted.current) return;
    
    const now = Date.now();
    if (!force && now - lastUserCheck.current < USER_CHECK_INTERVAL) {
      return;
    }

    try {
      const token = authService.getAccessToken();
      if (!token || !authService.validateToken(token)) {
        setUser(null);
        setAuthState(AUTH_STATES.IDLE);
        return;
      }

      const userData = await authService.getUserProfile(force);
      
      if (!isMounted.current) return;
      
      if (userData) {
        setUser(authService.normalizeUserData(userData));
        setAuthState(AUTH_STATES.SUCCESS);
        lastUserCheck.current = now;
      } else {
        setUser(null);
        setAuthState(AUTH_STATES.IDLE);
      }
    } catch (error) {
      if (isMounted.current) {
        setUser(null);
        setAuthState(AUTH_STATES.IDLE);
        handleError(error);
      }
    }
  }, [handleError, isInitialized]);

  const debouncedCheckAuthStatus = useMemo(
    () => debounce(checkAuthStatus, 1000),
    [checkAuthStatus]
  );

  useEffect(() => {
    if (isInitialized && !isCleaningUp.current) {
      const intervalId = setInterval(() => {
        debouncedCheckAuthStatus(false);
      }, USER_CHECK_INTERVAL);

      return () => {
        clearInterval(intervalId);
        debouncedCheckAuthStatus.cancel();
      };
    }
  }, [isInitialized, debouncedCheckAuthStatus]);

  useEffect(() => {
    const events = authService.getEvents();
    let isSubscribed = true;

    const handleTokenExpired = async () => {
      if (!isMounted.current || isRefreshing || isCleaningUp.current) return;
      
      try {
        setIsRefreshing(true);
        const newToken = await authService.silentRefresh();
        if (newToken && isMounted.current && !isCleaningUp.current) {
          await checkAuthStatus(true);
        }
      } catch (error) {
        if (isMounted.current && !isCleaningUp.current) {
          setUser(null);
          setAuthState(AUTH_STATES.IDLE);
          navigate('/login', { replace: true });
        }
      } finally {
        if (isMounted.current) {
          setIsRefreshing(false);
        }
      }
    };

    const handleTokenRefresh = () => {
      if (isSubscribed && isMounted.current && !isCleaningUp.current) {
        setAuthState(AUTH_STATES.SUCCESS);
      }
    };

    const handleUserUpdated = (updatedUser) => {
      if (isSubscribed && isMounted.current && !isCleaningUp.current) {
        setUser(updatedUser ? authService.normalizeUserData(updatedUser) : null);
      }
    };

    const handleAuthError = (error) => {
      if (isSubscribed && isMounted.current && !isCleaningUp.current) {
        handleError(error);
      }
    };

    const unsubscribeTokenRefresh = events.on(AUTH_EVENTS.TOKEN_REFRESH, handleTokenRefresh);
    const unsubscribeTokenExpired = events.on(AUTH_EVENTS.TOKEN_EXPIRED, handleTokenExpired);
    const unsubscribeUserUpdated = events.on(AUTH_EVENTS.USER_UPDATED, handleUserUpdated);
    const unsubscribeAuthError = events.on(AUTH_EVENTS.AUTH_ERROR, handleAuthError);

    return () => {
      isSubscribed = false;
      unsubscribeTokenRefresh();
      unsubscribeTokenExpired();
      unsubscribeUserUpdated();
      unsubscribeAuthError();
    };
  }, [checkAuthStatus, handleError, navigate, isRefreshing]);

  useEffect(() => {
    if (isInitialized && !user && authState !== AUTH_STATES.LOADING) {
      // navigate('/login', { replace: true });
    }
  }, [isInitialized, user, authState, navigate]);

  useEffect(() => {
    if (error) {
      const timeoutId = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [error]);

  const login = useCallback(async (identifier, password) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const userData = await authService.login(identifier, password);
      setUser(authService.normalizeUserData(userData));
      setAuthState(AUTH_STATES.SUCCESS);
      const destination = userData.is_staff ? '/admin/dashboard' : '/profile';
      navigate(destination, { replace: true });
      return userData;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to login'));
    }
  }, [navigate, handleError]);

  const logout = useCallback(async () => {
    if (isCleaningUp.current) return;
    
    try {
      isCleaningUp.current = true;
      setAuthState(AUTH_STATES.LOADING);
      
      // Clear state before logout
      setUser(null);
      setIsInitialized(false);
      
      await authService.logout();
      
      if (isMounted.current) {
        setAuthState(AUTH_STATES.IDLE);
        setIsInitialized(true);
        navigate('/login', { replace: true });
      }
    } catch (error) {
      if (isMounted.current) {
        setAuthState(AUTH_STATES.ERROR);
        handleError(error, 'Failed to logout');
      }
    } finally {
      isCleaningUp.current = false;
    }
  }, [navigate, handleError]);

  const register = useCallback(async (userData) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const result = await authService.register(userData);
      setAuthState(AUTH_STATES.SUCCESS);
      navigate('/login');
      return result;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to register'));
    }
  }, [navigate, handleError]);

  const registerVendor = useCallback(async (vendorData) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const result = await authService.registerVendor(vendorData);
      if (result.user) {
        const updatedUser = authService.normalizeUserData({
          ...user,
          ...result.user
        });
        setUser(updatedUser);
      }
      setAuthState(AUTH_STATES.SUCCESS);
      return result;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to register vendor account'));
    }
  }, [handleError, user]);

  const loginWithGoogle = useCallback(async (idToken) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const userData = await authService.loginWithGoogle(idToken);
      setUser(authService.normalizeUserData(userData));
      setAuthState(AUTH_STATES.SUCCESS);
      const destination = userData.is_staff ? '/admin/dashboard' : '/profile';
      navigate(destination, { replace: true });
      return userData;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to login with Google'));
    }
  }, [navigate, handleError]);

  const loginWithFacebook = useCallback(async (accessToken) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const userData = await authService.loginWithFacebook(accessToken);
      setUser(authService.normalizeUserData(userData));
      setAuthState(AUTH_STATES.SUCCESS);
      const destination = userData.is_staff ? '/admin/dashboard' : '/profile';
      navigate(destination, { replace: true });
      return userData;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to login with Facebook'));
    }
  }, [navigate, handleError]);

  const updateProfile = useCallback(async (userData) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const updatedUser = await authService.updateUserProfile(userData);
      setUser(authService.normalizeUserData(updatedUser));
      setAuthState(AUTH_STATES.SUCCESS);
      return updatedUser;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to update profile'));
    }
  }, [handleError]);

  const resetPassword = useCallback(async (email) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const result = await authService.resetPassword(email);
      setAuthState(AUTH_STATES.SUCCESS);
      navigate('/login');
      return result;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to reset password'));
    }
  }, [navigate, handleError]);

  const confirmResetPassword = useCallback(async (uidb64, token, newPassword) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const result = await authService.confirmResetPassword(uidb64, token, newPassword);
      setAuthState(AUTH_STATES.SUCCESS);
      navigate('/login');
      return result;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to confirm password reset'));
    }
  }, [navigate, handleError]);

  const resendActivation = useCallback(async (email) => {
    try {
      setAuthState(AUTH_STATES.LOADING);
      const result = await authService.resendActivation(email);
      setAuthState(AUTH_STATES.SUCCESS);
  
      return result;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to resend activation email'));
    }
  }, [handleError]);

  const refreshUserProfile = useCallback(async () => {
    try {
      if (isCleaningUp.current) {
        return null;
      }
      
      setAuthState(AUTH_STATES.LOADING);
      const userData = await authService.getUserProfile(true);
      if (userData && isMounted.current) {
        setUser(authService.normalizeUserData(userData));
      }
      setAuthState(AUTH_STATES.SUCCESS);
      return userData;
    } catch (error) {
      setAuthState(AUTH_STATES.ERROR);
      throw new Error(handleError(error, 'Failed to refresh user profile'));
    }
  }, [handleError]);

  const validateSession = useCallback(async () => {
    try {
      if (isCleaningUp.current) {
        return false;
      }
      const token = await authService.ensureValidToken();
      return Boolean(token);
    } catch (error) {
      return false;
    }
  }, []);

  const contextValue = useMemo(() => ({
    // Auth state
    user,
    loading: authState === AUTH_STATES.LOADING,
    error,
    initialized: isInitialized,
    authState,

    // Core auth methods
    login,
    logout,
    register,
    registerVendor,
    loginWithGoogle,
    loginWithFacebook,
    
    // Profile management
    updateProfile,
    refreshUserProfile,
    updateUser: (userData) => {
      const normalizedUser = authService.normalizeUserData(userData);
      setUser(normalizedUser);
      authService.setUser(normalizedUser);
    },

    // Password management
    resetPassword,
    confirmResetPassword,
    resendActivation,

    // Session management
    checkAuthStatus,
    validateSession,

    // User status checks
    isAuthenticated: Boolean(user && authService.isAuthenticated()),
    isAdmin: Boolean(user?.is_staff),
    isVendor: Boolean(user?.is_vendor),
    isVendorPending: Boolean(user?.vendorship_requested && !user?.is_vendor),
    
    // Role management
    hasRole: (role) => {
      const userRoles = [];
      if (user?.is_staff) userRoles.push(USER_ROLES.ADMIN);
      if (user?.is_vendor) userRoles.push(USER_ROLES.VENDOR);
      userRoles.push(USER_ROLES.USER);
      return userRoles.includes(role);
    },

    // User info helpers
    getUserFullName: () => authService.getUserFullName(),
    getFormattedJoinDate: () => authService.getFormattedJoinDate(),

    // Constants
    USER_ROLES,
  }), [
    // Dependencies
    user,
    authState,
    error,
    isInitialized,
    login,
    logout,
    register,
    registerVendor,
    loginWithGoogle,
    loginWithFacebook,
    updateProfile,
    refreshUserProfile,
    resetPassword,
    confirmResetPassword,
    resendActivation,
    checkAuthStatus,
    validateSession,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export {
  AuthContext,
  USER_ROLES,
  AUTH_EVENTS,
};

export default AuthProvider;