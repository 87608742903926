import React from 'react';

const LoadingSpinner = () => (
  <div
    className="fixed inset-0 flex flex-col justify-center items-center bg-gray-100 bg-opacity-75 z-50"
    role="status"
    aria-label="Loading"
  >
    {/* Outer Rotating Dashed Ring */}
    <div
      className="relative w-32 h-32 border-4 border-dashed border-blue-400 rounded-full animate-spin"
      style={{ animationDuration: '10s' }}
    ></div>
    
    {/* Main Rotating Gear */}
    <div
      className="absolute w-24 h-24 border-8 border-transparent border-t-8 border-gray-800 rounded-full animate-spin"
      style={{ animationDuration: '3s' }}
    >
      {/* Center Gear with Glowing Pulse using Tailwind's animate-ping */}
      <div className="absolute inset-0 m-auto w-12 h-12 bg-gradient-to-r from-blue-400 via-blue-300 to-blue-500 rounded-full shadow-2xl animate-ping"></div>
      
      {/* Orbiting Satellite Balls - using a rotating container trick */}
      <div
        className="absolute inset-0 flex justify-center items-center animate-spin"
        style={{ animationDuration: '2s', animationDelay: '0s' }}
      >
        <div
          className="w-4 h-4 bg-blue-500 rounded-full"
          style={{ transform: 'translateX(60px)' }}
        ></div>
      </div>
      <div
        className="absolute inset-0 flex justify-center items-center animate-spin"
        style={{ animationDuration: '2s', animationDelay: '0.2s' }}
      >
        <div
          className="w-3 h-3 bg-green-500 rounded-full"
          style={{ transform: 'translateX(60px)' }}
        ></div>
      </div>
      <div
        className="absolute inset-0 flex justify-center items-center animate-spin"
        style={{ animationDuration: '2s', animationDelay: '0.4s' }}
      >
        <div
          className="w-3 h-3 bg-red-500 rounded-full"
          style={{ transform: 'translateX(60px)' }}
        ></div>
      </div>
      <div
        className="absolute inset-0 flex justify-center items-center animate-spin"
        style={{ animationDuration: '2s', animationDelay: '0.6s' }}
      >
        <div
          className="w-4 h-4 bg-yellow-500 rounded-full"
          style={{ transform: 'translateX(60px)' }}
        ></div>
      </div>
      <div
        className="absolute inset-0 flex justify-center items-center animate-spin"
        style={{ animationDuration: '2s', animationDelay: '0.8s' }}
      >
        <div
          className="w-3 h-3 bg-purple-500 rounded-full"
          style={{ transform: 'translateX(60px)' }}
        ></div>
      </div>
      <div
        className="absolute inset-0 flex justify-center items-center animate-spin"
        style={{ animationDuration: '2s', animationDelay: '1s' }}
      >
        <div
          className="w-3 h-3 bg-pink-500 rounded-full"
          style={{ transform: 'translateX(60px)' }}
        ></div>
      </div>
    </div>
    
    {/* Engaging Text */}
    <p className="mt-8 text-xl font-semibold text-gray-800 animate-bounce">
      Making things happen...
    </p>
    <span className="sr-only">Loading...</span>
  </div>
);

export default LoadingSpinner;
